import ConfirmPurchaseSubscription from '../../components/Revisor/ConfirmPurchaseSubscription'
import React from 'react'
import Pagewrapper from '../../components/PageWrapper'

export default function () {
    return (
        <Pagewrapper>
            <ConfirmPurchaseSubscription />
        </Pagewrapper>
    )
}
