import { Button, message, Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { SyncOutlined } from '@ant-design/icons'
import { useAuthRevisor } from '../../hooks/useAuth'
import { httpPostRevisor } from '../../services/http'

export default function AdvisorConfirmPurchaseSubscription() {
    let location = useLocation()
    const [status, setStatus] = useState<boolean | null>(null)
    useAuthRevisor('')

    useEffect(() => {
        const sessionId = new URLSearchParams(location.search).get('session_id')
        if (!sessionId) return
        httpPostRevisor<Response>(`/auth-revisor/subscription-complete-checkout/${sessionId}`)
            .then((r) => {
                setStatus(true)
            })
            .catch((error) => {
                message.error(error.toString())
                setStatus(false)
            })
    }, [location.search])

    return (
        <div className="login-background">
            <div className="login-background-inner">
                <div className="login-container">
                    <div>
                        <Link to="/" replace>
                            <div className="logoImgLarge" />
                        </Link>
                        {(status === null && (
                            <Result
                                icon={<SyncOutlined spin />}
                                title="Awaiting server response.."
                            />
                        )) ||
                            (status === true && (
                                <Result
                                    status="success"
                                    title="Successfully Completed Checkout!"
                                    extra={[
                                        <Button type="primary" key="goback">
                                            <Link to="/revisor/">Go back</Link>
                                        </Button>,
                                    ]}
                                />
                            )) ||
                            (status === false && (
                                <Result
                                    title={
                                        'The service may take a couple of minutes before the' +
                                        ' process comes through. You can go back to the app' +
                                        ' and await the processing.' +
                                        ' If you think something is wrong feel free to contact' +
                                        ' us by E-mail or phone!'
                                    }
                                    extra={[
                                        <Button type="primary" key="goback">
                                            <Link to="/revisor/">Go back</Link>
                                        </Button>,
                                    ]}
                                />
                            ))}
                    </div>
                </div>
                <br />
            </div>
        </div>
    )
}
